import { connect } from 'react-redux';
import { Summary } from './Summary';

import {
    getAddGuestReservationObj,
    getAdults,
    getArrivalDateObject,
    getBillableGuest,
    getCabanaDeposit,
    getDepartureDateObject,
    getDepositePaid,
    getKids,
    getNumberOfNights,
    getOffersDiscountApplied,
    getParkingFee,
    getPointsDiscountApplied,
    getPrivateLodgingFee,
    getRateType,
    getResortFee,
    getRoomDeposit,
    getSuiteDetails,
    getSuppressRate,
    getSustainabilityFee,
    getTaxesAndFeesForReservation,
    getTotalBillAndNonBillGuest,
    getTotalRoomRate
} from '../../../../../store/componentStores/ReservationDetailsEntity/reservationDetailsEntity.selectors';
import {
    getAditionalPackagesFromAddApackage,
    getDueAtCheckinAmountWithAdditionalPackages,
    getSummaryTotalWithAdditionalPackages,
    getTaxesAmountWithAdditionalPackages,
    packagesWithAmountFromReservationData
} from '../../../../../store/reducers';

import { isEmpty } from 'lodash-es';
import { getTaxAndFeesLabels } from '../../../../../store/componentStores/LodgeConfig/lodgeConfig.selectors';
import { isArrayEmpty } from '../../../../../utilities/arraysUtils';
import { getDateMonthDayFormat } from '../../../../../utilities/dates';
import { getGuestCountLabel } from '../../../../../utilities/guestsUtils';
import { getTaxesAndFees, getTaxesAndFeesDynamic } from '../../../../../utilities/taxes';

const mapStateToProps = state => {
  const packages = packagesWithAmountFromReservationData(state) ?? [];

  const hasPackages = packages && !isArrayEmpty(packages);

  const { offerCode } = getSuiteDetails(state.entities.reservationDetails);

  const taxAndFeeLabels = getTaxAndFeesLabels(state.lodgeConfig);

  const taxesAndFeesPrices = getTaxesAndFeesForReservation(state.entities.reservationDetails);

  const taxesAndFees =
    !isEmpty(taxAndFeeLabels) && !isEmpty(taxesAndFeesPrices)
      ? getTaxesAndFeesDynamic(taxAndFeeLabels, taxesAndFeesPrices)
      : getTaxesAndFees(
          getSustainabilityFee(state.entities.reservationDetails),
          getTaxesAmountWithAdditionalPackages(state),
          getResortFee(state.entities.reservationDetails),
          getParkingFee(state.entities.reservationDetails),
          getPrivateLodgingFee(state.entities.reservationDetails)
        );

  const addGuestReservation = getAddGuestReservationObj(state.entities.reservationDetails);

  const voyagerPointsDiscount = getPointsDiscountApplied(state.entities.reservationDetails);
  const offersPointsDiscount = getOffersDiscountApplied(state.entities.reservationDetails);
  const hasDiscountCharges = voyagerPointsDiscount || offersPointsDiscount ? true : false;

  return {
    additionalPackages: getAditionalPackagesFromAddApackage(state, true),
    checkIn: getDateMonthDayFormat(getArrivalDateObject(state.entities.reservationDetails)),
    checkOut: getDateMonthDayFormat(getDepartureDateObject(state.entities.reservationDetails)),
    depositPaid: getDepositePaid(state.entities.reservationDetails),
    dueAtCheckinAmount: getDueAtCheckinAmountWithAdditionalPackages(state),
    guestCountText: getGuestCountLabel(
      addGuestReservation ? addGuestReservation?.guests?.adults : getAdults(state.entities.reservationDetails),
      addGuestReservation ? addGuestReservation?.guests?.children?.length : getKids(state.entities.reservationDetails),
      null,
      true
    ),
    addGuestReservation: addGuestReservation,
    hasAdditionalCharges: taxesAndFees && taxesAndFees.length > 0,
    hasSelectedPackages: hasPackages,
    numberOfGuests: getBillableGuest(state.entities.reservationDetails),
    numberOfNights: getNumberOfNights(state.entities.reservationDetails),
    packages: packages,
    promoCode: offerCode,
    resortLocation: state.currentLodge,
    suiteTotal: getTotalRoomRate(state.entities.reservationDetails),
    taxesAndFees: taxesAndFees,
    total: getSummaryTotalWithAdditionalPackages(state),
    suppressRate: getSuppressRate(state.entities.reservationDetails),
    cabanaDeposit: getCabanaDeposit(state.entities.reservationDetails),
    roomDeposit: getRoomDeposit(state.entities.reservationDetails),
    hasDiscountCharges,
    voyagerPointsDiscount,
    offersPointsDiscount,
    totalBillAndNonBillGuest: getTotalBillAndNonBillGuest(state.entities.reservationDetails),
    rateType: getRateType(state.entities.reservationDetails)
  };
};

const ReservationSummary = connect(mapStateToProps, null)(Summary);

export { ReservationSummary };

